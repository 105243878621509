import httpClient from "@/api/httpClient.js";

const resource = "domains";

export function fetchDomains() {
  return httpClient.get(`/${resource}`);
}

export function createDomain(domain) {
  return httpClient.post(`/${resource}`, {
    domain,
  });
}

export function updateDomain(id, domain) {
  return httpClient.put(`/${resource}/${id}`, {
    domain,
  });
}

export function fetchDomain(id) {
  return httpClient.get(`/${resource}/${id}`);
}

export function fetchParams() {
  return httpClient.get(`/${resource}/params`);
}
