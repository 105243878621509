<template>
  <v-form>
    <validation-provider
      v-slot="{ errors }"
      :rules="validations.url"
      name="url">
      <v-text-field
        name="url"
        label="URL"
        :value="value.url"
        :error-messages="errors"
        @input="$emit('input', {...value, url: $event})" />
    </validation-provider>
    <validation-provider
      v-slot="{ errors }"
      :rules="validations.style"
      name="style">
      <v-select
        name="style"
        label="Style"
        :value="value.style"
        item-value="id"
        item-text="name"
        :items="params.styles"
        :error-messages="errors"
        @input="$emit('input', {...value, style: $event})" />
    </validation-provider>
  </v-form>
</template>

<script>
export default {
  name: "DomainForm",
  props: {
    value: {
      type: Object,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
    validations: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
